<template>
    <div class="delete-account-container din">
        <h1 style="color: white; font-weight: 600;">ACE Challenge User account deletion</h1>

        <div style="border: 1px solid #F2F2F2; border-radius: 8px; padding: 24px;">
            <p style="line-height: 22px; font-weight: 500; ;">This link pertains to the <span
                    style="color: black; font-weight: 700;"> ACE Challenge app</span> available on the
                Google Play store. If you wish to delete your
                account, please follow these steps:</p>

            <ol>
                <li>
                    <p style="margin: 0; line-height: 22px">
                        Open the <span style="color: black; font-weight: 700;">ACE Challenge app</span> on your device.
                    </p>
                </li>
                <li>
                    <p style="margin: 0; line-height: 22px">
                        Open the hamburger menu by clicking on the three horizontal lines on the top left corner of the
                        screen.
                    </p>
                </li>
                <li>
                    <p style="margin: 0; line-height: 22px">
                        Go to your account.
                    </p>
                </li>
                <li>
                    <p style="margin: 0; line-height: 22px">
                        Locate and select the option to delete your account.
                    </p>
                </li>
                <li>
                    <p style="margin: 0; line-height: 22px">
                        Follow the on-screen instructions to complete the account deletion process.
                    </p>
                </li>
            </ol>

            <p style="line-height: 22px; font-weight: 500;">By initiating the account deletion process,
                the following types of data will be <span style="color: black; font-weight: 700;">deleted:</span> </p>

            <ul>
                <li>
                    <p style=" margin: 0; line-height: 22px">
                        User profile data
                    </p>
                </li>
                <li>
                    <p style="margin: 0; line-height: 22px">Usage history data</p>
                </li>
            </ul>

            <p style="line-height: 22px; font-weight: 500;">Any retained data will be <span
                    style="color: black; font-weight: 700;">permanently deleted.</span>
            </p>
        </div>
    </div>
</template>
<style>
.delete-account-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #f87e5e;
    padding: 40px 32px;
}
</style>