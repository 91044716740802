<template>
  <section class="section1" id="section-1">
    <div class="container">
      <div class="row mb-5 mt-4">
        <div id="section1-header">
          <h3 class="text-header font-size-l">Zo simpel is het</h3>
          <span class="text-body font-size-m"
            >Download de app, speel en win!</span
          >
        </div>
      </div>
      <div id="section1-mockups" class="row">
        <div class="col-xs-12 col-lg-4 column-left">
          <div class="card">
            <img
              class="iphone-x"
              src="../assets/section2/iPhone_section_02_03@2x.png"
              alt="iPhone"
            />
            <div class="card-body">
              <h5 class="card-title font-size-s">Zet in</h5>
              <p class="card-text font-size-s">
                Plaats tegoed op je account. Scan de QR-code bij de hole met de
                app en bepaal jouw inzet. <br />
                Speel al vanaf €3.
              </p>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-lg-4 column-middle">
          <div class="card">
            <img
              class="iphone-x"
              src="../assets/section2/iPhone_section_02_02@2x.png"
              alt="iPhone"
            />
            <div class="card-body">
              <h5 class="card-title font-size-s">Sla die hole-in-one</h5>
              <p class="card-text font-size-s">
                Na de inzet heb je 20 seconden voor een oefenswing. Als de
                teller afloopt, wordt het scherm rood en sla je je bal.
              </p>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-lg-4 column-right">
          <div class="card">
            <img
              class="iphone-x"
              src="../assets/section2/iPhone_section_02_01@2x.png"
              alt="iPhone"
            />
            <div class="card-body">
              <h5 class="card-title font-size-s">
                Deel met vrienden en analyseer
              </h5>
              <p class="card-text font-size-s">
                Je wint altijd! Bij elke deelname spaar je ACE Miles én ontvang
                je de video van jouw slag. Deel met vrienden én analyseer.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.section1 {
  padding-top: 16rem;
  padding-bottom: 9rem;
  background: #f87e5e;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  scroll-snap-align: center;
}

#section1-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.text-header {
  text-transform: none;
  font-weight: 600;
  line-height: 60px;
}

.card-title {
  text-transform: none;
  font-weight: 600 !important;
  text-align: left;
}

.card-text {
  font-size: 1.125rem;
  line-height: 32px;
  font-weight: 400 !important;
  max-width: 313px;
  text-align: left;
}

.text-body {
  color: #fff !important;
  font-size: 2rem;
  font-weight: 400;
  line-height: 48px;
  text-align: left;
}

#section1-mockups {
  display: flex;
  flex-direction: row;
}

.iphone-x {
  max-width: 25rem;
}

.card {
  background-color: transparent;
  border: 0px;
  width: min-content;
}

.column-left {
  display: flex;
  justify-content: flex-start;
}

.column-middle {
  display: flex;
  justify-content: center;
}

.column-right {
  display: flex;
  justify-content: flex-end;
}

/* Up to large devices */
@media (max-width: 991px) {
  .column-left {
    display: flex;
    justify-content: center;
  }

  .column-middle {
    display: flex;
    justify-content: center;
  }

  .column-right {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 767.98px) {
  .section1 {
    text-align: center;
  }

  .card {
    margin-bottom: 4rem;
  }

  .card-title {
    font-size: 24px;
    text-align: center;
  }

  .card-text {
    text-align: center;
  }

  .text-body {
    color: #fff !important;
    font-size: 24px;
    opacity: 0.66;
    text-align: center;
  }

  .iphone-x {
    order: 1;
  }

  .card-body {
    order: 2;
  }
}
</style>
