<template>
  <footer class="website-footer">
    <div
      class="row d-flex"
      style="
        font-weight: bold;
        margin: 0 3.875rem 0 3.875rem;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
      "
    >
      <div
        class="col-xs-12 col-md-6 font-size-xxs d-flex flex-column justify-content-center text-style copyright-text"
      >
        <span
          >© 2023. All rights reserved, The Ace Challenge BV - 3941 BW Doorn,
          Moersbergselaan 17. KVK: 75324911</span
        >
      </div>
      <div class="col-xs-12 col-md-6 agreement-text">
        <a href="https://theacechallenge.com/terms_and_conditions.pdf"
          ><span class="font-size-xxs">Algemene voorwaarden</span></a
        >
        <span class="font-size-xxs"> - </span>
        <a href="https://theacechallenge.com/privacy_policy.pdf"
          ><span class="font-size-xxs">Privacy</span></a
        >
      </div>
    </div>
  </footer>
</template>

<style scoped>
.agreement-text {
  text-align: right !important;
}
.website-footer {
  background: #102f27;
}

.text-style {
  font-family: "Inter", "sans-serif" !important;
}

a {
  text-decoration: none;
}

.container {
  text-align: center;
  padding-bottom: 1rem;
}
.text-header {
  text-transform: none;
  color: #ffffff;
}
span {
  color: #fff;
  font-size: 12px !important;
  line-height: 18px;
  font-weight: 400;
}
img {
  height: 1rem;
}
/* Up to large devices */
@media (max-width: 991px) {
  .column-left {
    display: flex;
    justify-content: center;
  }

  .column-middle {
    display: flex;
    justify-content: center;
  }

  .column-right {
    display: flex;
    justify-content: center;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .column-left {
    display: flex;
    justify-content: flex-start;
  }

  .column-middle {
    display: flex;
    justify-content: center;
  }

  .column-right {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 767.98px) {
  .agreement-text {
    text-align: center !important;
  }

  .copyright-text {
    text-align: center !important;
  }
}
</style>
