<template>
  <div class="overlay-path"></div>
  <section class="section1" id="section-1">
    <div class="container">
      <div class="row mb-5 mt-4">
        <div id="section1-header">
          <h3 class="text-header font-size-l">De winst</h3>
        </div>
      </div>
      <div id="section1-mockups" class="row">
        <div class="col-xs-12 col-md-4 column-left">
          <div class="card">
            <div
              style="
                width: 461px;
                height: 352px;
                display: flex;
                justify-content: center;
              "
            >
              <img
                src="../assets/section4/rolex-gmt-master-ii-pepsi.e59c5287.png.png"
                alt="iPhone"
              />
            </div>
            <div class="card-body">
              <h5 class="card-title font-size-s">1. Rolex horloge*</h5>
              <p class="card-text font-size-s">De prijs bij een hole-in-one</p>
              <p
                class="font-size-xs"
                style="
                  color: rgba(255, 255, 255, 0.66);
                  font-style: italic;
                  font-weight: 400;
                  line-height: 24px;
                  text-align: center;
                "
              >
                *Afbeelding horloge is een voorbeeld.<br />
                Hier kunnen geen rechten aan <br />
                worden ontleend.
              </p>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-4 column-middle">
          <div class="card">
            <div
              style="
                width: 100%;
                height: 352px;
                display: flex;
                justify-content: center;
              "
            >
              <img
                src="../assets/section4/glove.png"
                alt="iPhone"
                style="width: 100%"
              />
            </div>
            <div class="card-body">
              <h5 class="card-title font-size-s">2. Titleist golf gloves</h5>
              <p class="card-text font-size-s">
                Sla de bal binnen één meter van de pin en win twee
                golfhandschoenen van Titleist
              </p>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-4 column-right">
          <div class="card">
            <div
              style="
                width: 100%;
                height: 352px;
                display: flex;
                justify-content: center;
              "
            >
              <img
                src="../assets/section4/balls.png"
                alt="iPhone"
                style="width: 100%"
              />
            </div>
            <div class="card-body">
              <h5 class="card-title font-size-s">3. Mulligan golfbal</h5>
              <p class="card-text font-size-s">
                Wanneer je de bal op de rest van de green hebt geslagen
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <a
          style="margin-right: 26px"
          href="https://apps.apple.com/us/app/ace-challenge/id1569665781"
          ><img src="../assets/section1/Appstore_logo.png" alt="App Store"
        /></a>
        <a
          href="https://play.google.com/store/apps/details?id=com.acechallenge.app"
          ><img
            src="../assets/section1/Googlestore_logo.png"
            alt="Google Store"
        /></a>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.overlay-path {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../assets/section4/section4-shape.png") no-repeat;
  background-size: 105% 300%;
  background-position: 0 -4%;
  z-index: 0;
}
.section1 {
  padding-top: 6.25rem;
  padding-bottom: 9rem;
  background: #102f27;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  scroll-snap-align: center;
}

#section1-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.text-header {
  text-transform: none;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
}

.card-title {
  text-transform: none;
  font-weight: 600 !important;
  text-align: center;
}

.card-text {
  line-height: 42px;
  font-weight: 400 !important;
  text-align: center;
}

.text-body {
  color: #fff !important;
  font-size: 2rem;
  font-weight: 400;
  line-height: 48px;
  text-align: center;
}

#section1-mockups {
  display: flex;
  padding-bottom: 60px;
}

.iphone-x {
  max-width: 25rem;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  background-color: transparent;
  row-gap: 60px;
  width: 100%;
  border: 0px;
}

.column-left {
  display: flex;
  justify-content: center;
  align-items: start;
}

.column-middle {
  display: flex;
  justify-content: center;
  align-items: start;
}

.column-right {
  display: flex;
  justify-content: center;
  align-items: start;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-starts;
  justify-self: center;
  align-self: center;
  padding: 0;
}

/* Up to large devices */
@media (max-width: 991px) {
  .column-left {
    display: flex;
    justify-content: center;
  }

  .column-middle {
    display: flex;
    justify-content: center;
  }

  .column-right {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 767.98px) {
  .section1 {
    text-align: center;
  }

  .card {
    align-items: center;
    margin-bottom: 4rem;
  }

  .card-title {
    font-size: 24px;
    text-align: center;
  }

  .card-text {
    text-align: center;
  }

  .text-body {
    color: #fff !important;
    font-size: 24px;
    opacity: 0.66;
    text-align: center;
  }

  .iphone-x {
    order: 1;
  }

  .card-body {
    order: 2;
  }
}
</style>
