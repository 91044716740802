<script>

import TheMain from './layout/TheMain.vue';
import TheHeader from './layout/TheHeader.vue';
import TheFooter from './layout/TheFooter.vue';

export default {
    components: {
        TheMain,
        TheHeader,
        TheFooter
    },
    created() {
        document.title = 'The Ace Challenge';
    }
};


</script>

<template>
    <TheHeader class="heading-temp"></TheHeader>
    <div class="green-bg" style="z-index: 9999">
        <TheMain></TheMain>
    </div>
    <TheFooter class="footer-temp"></TheFooter>
</template>