<template>
  <section class="section2 flex row justify-content-around" id="section-2">
    <div class="col-xs-12 col-md-6 align-self-center" id="left-box">
      <img
        class="iphone-x"
        src="../assets/section3/iPhone_section_04_01.png"
        alt="iPhone"
      />
    </div>
    <div
      class="col-xs-12 col-md-6 align-self-center flex row justify-content-center"
      style="text-align: left"
      id="right-box"
    >
      <p
        class="text-header mb-4"
        style="font-size: 60px; letter-spacing: -2px; line-height: 72px"
      >
        The ACE cup
      </p>
      <p class="mb-5 text-desc" style="">
        Kwalificeer je voor ons volledig verzorgde eindtoernooi! <br />
        Op 28 oktober vindt de eerste editie van The ACE Cup '23 <br />
        plaats op Golfcentrum de Hoge Dijk.
      </p>

      <p class="mb-5 text-desc" style="">
        Scoor voldoende punten voor een plek in de maandelijkse <br />
        top 5 en kwalificeer je zo voor The ACE Cup.
      </p>

      <p class="mb-5 text-desc" style="">Waar wacht je nog op? 😉</p>
      <div style="display: flex; justify-content: start">
        <a
          style="margin-right: 26px"
          href="https://apps.apple.com/us/app/ace-challenge/id1569665781"
          ><img src="../assets/section1/Appstore_logo.png" alt="App Store"
        /></a>
        <a
          href="https://play.google.com/store/apps/details?id=com.acechallenge.app"
          ><img
            src="../assets/section1/Googlestore_logo.png"
            alt="Google Store"
        /></a>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.bg-phone-image {
  position: absolute;
  height: 900px;
  width: 40%;
}

.section2 {
  background: url("../assets/section3/Background_image.png"), #102F27;
  background-size: 100% auto;
  background-repeat: no-repeat;
  min-height: 100vh;
  /* padding: 0 5rem; */
}

.text-header {
  font-family: "Inter", "sans-serif";
  text-transform: none;
  font-weight: 800;
}

.text-sub-header {
  font-size: 24px;
  font-family: "Inter", "sans-serif";
  text-transform: none;
  font-weight: 800;
  letter-spacing: -0.25px;
  line-height: 0px;
}

.text-desc {
  font-size: 24px;
  line-height: 48px;
  font-weight: 400;
  font-family: "Inter", "sans-serif";
}

.text-sub-desc {
  font-size: 24px;
  line-height: 48px;
  font-weight: 400;
  font-family: "Inter", "sans-serif";
  letter-spacing: -1px;
}

#left-box {
  display: flex;
  justify-content: center;
}

#right-box {
  display: flex;
  justify-content: center;
  padding: 5.75rem 0 5.75rem 0;
}

.iphone-bg {
  position: absolute;
  right: 0;
  bottom: 0;
}

.iphone-bg img {
  max-width: 50rem;
  z-index: 0;
}

#section2-iphone-mockup {
  position: absolute;
  top: 10%;
}
.iphone-x {
  transform: scale(1);
  /* height: 768px;
  width: calc(360 / 50 * 768px); */
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .iphone-x {
    transform: scale(0.6);
  }

  #badges img {
    width: 50%;
    max-width: 10rem;
    height: 2.75rem;
  }
  #section2-iphone-mockup {
    position: relative;
  }
}

@media (max-width: 767.98px) {
  #right-box {
    display: flex;
    justify-content: center;
    padding: 3.75rem;
  }
  .section2 {
    background: url("../assets/section3/Background_image.png");
    background-size: cover;
    background-repeat: no-repeat;
    /* padding: 0 5rem; */
  }

  .text-header {
    font-family: "Inter", "sans-serif";
    text-transform: none;
    font-weight: 800;
    font-size: 32px !important;
    text-align: center;
  }

  .text-sub-header {
    font-family: "Inter", "sans-serif";
    text-transform: none;
    font-weight: 700;
    font-size: 18px !important;
    letter-spacing: -0.25px;
    line-height: -10px;
  }

  .text-desc {
    font-size: 24px !important;
    text-align: center;
  }

  .text-sub-desc {
    font-size: 18px !important;
    text-align: center;
    letter-spacing: -1px;
    line-height: 30px;
  }

  #right-box {
    /* position: relative; */
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
</style>
