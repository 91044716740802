import { createApp } from "vue";
import { createRouter, createWebHistory } from 'vue-router';
import App from "./App.vue";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../public/style.css";
import DeleteAccountExplanation from "./DeleteAccountExplanation.vue";
import HomePage from "./HomePage.vue";

const routes = [
    { path: '/', component: HomePage },
    { path: '/account', component: DeleteAccountExplanation },
];
// Create the router instance
const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
});

const app = createApp(App);

app.use(router);
app.mount("#app");
